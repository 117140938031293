<template>
    <div>
        
    </div>
</template>
<script>
export default {
    mounted(){
        console.log(this.$route.query)
        this.$get('/freight/getUrlscheme',{id:this.$route.query.id,type:this.$route.query.type}).then(res =>{
            window.location.href = res.data
        })
    }
}
</script>